<template>
  <div class="instruction">
    使用说明
    <!-- <img src="" alt=""> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
$primary-color: #ff8757;


</style>